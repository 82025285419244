import ApplicationController from '../../../javascript/controllers/application_controller'
import { newInstance, EVENT_CONNECTION_MOVED, EVENT_CONNECTION_DETACHED, EVENT_CONNECTION, FALSE } from "@jsplumb/browser-ui"

export default class extends ApplicationController {

  static values = { 
    triggered: {type: String, default: 'false' },
    suppressplumbing: {type: String, default: 'false' }
  } 


  initialize() {
    super.initialize()
    this.endpointTypes = {
      "source": {
        cssClass: "source-endpoint",
        paintStyle: { fill: "#39ac73" }
      },
      "target": {
        cssClass: "connected-target-endpoint",
        paintStyle: { fill: "#6666ff" }
      },
      "possible-target": {
        cssClass: "unconnected-target-endpoint",
        paintStyle: { fill: "#DDD" }
      }
    }
    
      this.plumped = false;
  }

  connect() {
    super.connect();
    // console.log("eventTriggered: ", this.triggeredValue)
    // console.log(`## suppress: ${this.suppressplumbingValue} ##`)
    // console.log(`## enable Edit: ${this.editEnabled()} ##`)

    if(this.triggeredValue == "false") {
      this.plumbIt();
    }
  }

  ///
  // this is a getter function that will be used to determine if the plumbing is enabled
  ///
  editEnabled() {
    return !(this.suppressplumbingValue == 'true')
  }

  ///
  // this is the main function that will be called to initialize the jsplumb
  // it will be called after the page is loaded and the controller is connected
  ///
  plumbIt() {
    if (this.plumped) {
      return;
    }
    this.plumped = true;
    const rootElement = this.element;
    const controller = this;
    // console.log("## plumbIt ##")
    const $rootElement = $(rootElement); // Now you can use the rootElement variable to access the root element of the controller
    const connectorDefault = this.editEnabled() ? true : false;
    // console.log("## connectorDefault: ", connectorDefault);
    // console.log("## rootElement: ", $rootElement);

    $rootElement.find(".row.skill-row").each(function (index) {
      // console.log(" ## container is: ", this);
      const $this = $(this);
      const plumber = newInstance({
        container: this
      });
      plumber.setSuspendDrawing(true);

      plumber.registerEndpointTypes(controller.endpointTypes);
      plumber.importDefaults({
        maxConnections: 1,
        reattachConnections: this.editEnabled ? false : true,
        deleteEndpointsOnDetach: false,
        endpoint: {
          type: "Dot",
          options: { radius: 8 }
        },
        connector: {
          type: "Straight",
          options: { gap: 10 }
        }
      });

      // const ssid = `${$(this).data("sss-id")}`
      const sources = $this.find('.skill-level .source');
      const connectedSource = sources.filter(".connected");
      if (connectedSource.length) {
        const target = $this.find(".target").first()[0];
        const sourceEndpoint = plumber.addEndpoint(connectedSource[0], {
          anchor: "Right",
          source: connectorDefault,
          target: connectorDefault,
          type: 'source'
        });
        const targetEndpoint = plumber.addEndpoint(target, {
          anchor: "Left",
          target: connectorDefault,
          type: 'target'
        });
        
        const connection = plumber.connect({
          source: sourceEndpoint,
          target: targetEndpoint,
        });

      }
      const connectableSource = sources.filter(".connectable");
      if (connectableSource.length) {
        plumber.addEndpoint(connectableSource[0], {
          anchor: "Right",
          source: connectorDefault,
          target: connectorDefault,
          type: 'source'
        });
      }
      if(controller.editEnabled()) {
        const connectableTargets = $this.find(".skill-level .possible-target");
        connectableTargets.each(function (index) {
          plumber.addEndpoint(this, {
            anchor: "Left",
            target: connectorDefault,
            type: "possible-target",
          });
        });
      }

      plumber.setSuspendDrawing(false, true);
      // $("#skill_set_part .skill-development .source, #skill_set_part .skill-development .possible-target, #skill_set_part .skill-development .target").css({display: "none", position: "initial" })
      plumber.bind(EVENT_CONNECTION_MOVED, (p) => {
        // console.log("## Connection Moved ##")
        // const sreid = p.newEndpoint.element.dataset.sreid
        // const level = p.newEndpoint.element.dataset.level
        // console.log("level: ", level)
        // console.log("sreid: ", sreid)
        // console.log("p: ", p)
        p.originalEndpoint.setType("possible-target");
      });

      plumber.bind(EVENT_CONNECTION_DETACHED, (p) => {
        // console.log("## Connection Detached ##")
        const sreid = p.source.dataset.sreid;
        const level = p.source.dataset.level;
        // console.log(p)
        // console.log("level: ", level)
        // console.log("sreid: ", sreid)
        // console.log(p.target)
        // console.log(p.target.dataset.level)
        controller.stimulate('Skill#adapt_target_rating', sreid, level).then(() => {
          p.targetEndpoint.setType("possible-target");
        });
      });

      plumber.bind(EVENT_CONNECTION, (p) => {
        // console.log("## Connection Created ##")
        const sreid = p.source.dataset.sreid;
        const level = p.target.dataset.level;
        // console.log(p)
        // console.log(p.source.dataset.level)
        // console.log("sreid: ", sreid)
        // console.log(p.target)
        // console.log("target level: ", level)
        controller.stimulate('Skill#adapt_target_rating', sreid, level).then(() => {
          p.targetEndpoint.setType("target");
        });
      });

    });
  }

}
