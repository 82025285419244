{
  "en": {
    "date": {
      "abbr_day_names": [
        "Sun",
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat"
      ],
      "abbr_month_names": [
        null,
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"
      ],
      "day_names": [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ],
      "formats": {
        "default": "%Y-%m-%d",
        "long": "%B %d, %Y",
        "short": "%b %d"
      },
      "month_names": [
        null,
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ],
      "order": [
        "year",
        "month",
        "day"
      ]
    },
    "datetime": {
      "distance_in_words": {
        "about_x_hours": {
          "one": "about 1 hour",
          "other": "about %{count} hours"
        },
        "about_x_months": {
          "one": "about 1 month",
          "other": "about %{count} months"
        },
        "about_x_years": {
          "one": "about 1 year",
          "other": "about %{count} years"
        },
        "almost_x_years": {
          "one": "almost 1 year",
          "other": "almost %{count} years"
        },
        "half_a_minute": "half a minute",
        "less_than_x_minutes": {
          "one": "less than a minute",
          "other": "less than %{count} minutes"
        },
        "less_than_x_seconds": {
          "one": "less than 1 second",
          "other": "less than %{count} seconds"
        },
        "over_x_years": {
          "one": "over 1 year",
          "other": "over %{count} years"
        },
        "x_days": {
          "one": "1 day",
          "other": "%{count} days"
        },
        "x_minutes": {
          "one": "1 minute",
          "other": "%{count} minutes"
        },
        "x_months": {
          "one": "1 month",
          "other": "%{count} months"
        },
        "x_seconds": {
          "one": "1 second",
          "other": "%{count} seconds"
        },
        "x_years": {
          "one": "1 year",
          "other": "%{count} years"
        }
      },
      "dotiw": {
        "days": {
          "one": "1 day",
          "other": "%{count} days"
        },
        "hours": {
          "one": "1 hour",
          "other": "%{count} hours"
        },
        "less_than_x": "less than %{distance}",
        "minutes": {
          "one": "1 minute",
          "other": "%{count} minutes"
        },
        "months": {
          "one": "1 month",
          "other": "%{count} months"
        },
        "seconds": {
          "one": "1 second",
          "other": "%{count} seconds"
        },
        "weeks": {
          "one": "1 week",
          "other": "%{count} weeks"
        },
        "years": {
          "one": "1 year",
          "other": "%{count} years"
        }
      },
      "dotiw_compact": {
        "about_x_years": {
          "any": "~%{count}y",
          "one": "~1y"
        },
        "almost_x_years": {
          "any": "~%{count}y",
          "one": "~1y"
        },
        "days": {
          "one": "1d",
          "other": "%{count}d"
        },
        "hours": {
          "one": "1h",
          "other": "%{count}h"
        },
        "last_word_connector": "",
        "less_than_x": "<%{distance}",
        "minutes": {
          "one": "1m",
          "other": "%{count}m"
        },
        "months": {
          "one": "1mo",
          "other": "%{count}mo"
        },
        "over_x_years": {
          "one": ">1y",
          "other": ">%{count}y"
        },
        "seconds": {
          "one": "1s",
          "other": "%{count}s"
        },
        "two_words_connector": "",
        "weeks": {
          "one": "1w",
          "other": "%{count}w"
        },
        "words_connector": "",
        "years": {
          "one": "1y",
          "other": "%{count}y"
        }
      },
      "prompts": {
        "day": "Day",
        "hour": "Hour",
        "minute": "Minute",
        "month": "Month",
        "second": "Seconds",
        "year": "Year"
      },
      "today_in_words": "Heute",
      "yesterday_in_words": "Gestern"
    },
    "dict": {
      "active": "active",
      "adapt": "adapt",
      "address": "address",
      "agreements": "Agreements",
      "all": "all",
      "answer": {
        "one": "answer",
        "other": "answers"
      },
      "appointment": {
        "one": "appointment",
        "other": "appointments"
      },
      "back": "back",
      "blog_entries": "learn modules",
      "cancel": "cancel",
      "certificate": {
        "one": "certificate",
        "other": "certificates"
      },
      "clone": "Clone",
      "contact_people": "contact people",
      "continue": "continue",
      "correct": "correct",
      "current": "current",
      "day": "day",
      "de": "German",
      "delete": "delete",
      "description": "Description",
      "development_path": "Development Path",
      "edit": "Edit",
      "email": "email",
      "empty": "No entries present",
      "en": "English",
      "es": "Spanish",
      "event": {
        "one": "event",
        "other": "events"
      },
      "fr": "French",
      "full_name": "name",
      "gtc": "General terms and conditions",
      "hero_slider_elements": "banner",
      "hour": "hour",
      "hu": "Hungarian",
      "in_timezone": "Timezone %{timezone}",
      "inactive": "inactive",
      "index": "index",
      "interaction_needed": "action needed",
      "invoice": "invoice",
      "ja": "Japanese",
      "journey": {
        "one": "Journey",
        "other": "Journeys"
      },
      "language": {
        "one": "Language",
        "other": "Languages"
      },
      "learn_card": {
        "index": "Learn Card No. %{index}"
      },
      "learn_path": "learning path",
      "learn_paths": "learning paths",
      "learn_unit": {
        "one": "Learning Unit",
        "other": "Learning Units"
      },
      "locations": "locations",
      "mandatory_field": "Mandatory field",
      "messages": {
        "read": "read",
        "unread": "unread"
      },
      "minute": "minute",
      "module": {
        "one": "Module",
        "other": "Modules"
      },
      "month": "month",
      "name": {
        "one": "name",
        "other": "names"
      },
      "nano_certificate": "nano certificate",
      "nano_certificates": "nano certificates",
      "no_data": "no data available",
      "open": "Open",
      "order": "order",
      "parent": "parent",
      "participants": "participants",
      "permission": {
        "one": "permission",
        "other": "permissions"
      },
      "phase": {
        "one": "Learning Phase",
        "other": "Learning Phases"
      },
      "phone_number": "phone number",
      "point": {
        "one": "point",
        "other": "points"
      },
      "primary": "Primary",
      "private": "Private",
      "product": "product",
      "products": "products",
      "profile": {
        "one": "profile",
        "other": "profiles"
      },
      "project_experience": "Project Experience",
      "question": {
        "one": "question",
        "other": "questions"
      },
      "reminders": "Reminders",
      "restart": "restart",
      "result": {
        "one": "result",
        "other": "results"
      },
      "salutations": {
        "company": "Company",
        "madame": "Misses",
        "sir": "Mister"
      },
      "scope_tag": {
        "one": "scope tag",
        "other": "scope tags"
      },
      "search_profile": "search profile",
      "second": "second",
      "secondary": "Secondary",
      "send": "send",
      "show": "Show",
      "shown_from_short": "from",
      "shown_until_short": "until",
      "silent": "deactivated",
      "skill": {
        "one": "skill",
        "other": "skills"
      },
      "skill_category": {
        "one": "skill category",
        "other": "skill categories"
      },
      "skill_rating": {
        "one": "skill rating",
        "other": "skill ratings"
      },
      "slide": {
        "index": "Slide No. %{index}"
      },
      "statistics": "statistics",
      "stop": "stop",
      "supervised_people": "supervised people",
      "supervised_person": "supervised person",
      "take": "take",
      "template_new": "Create Template",
      "tenant": {
        "one": "tenant",
        "other": "tenants"
      },
      "tenant_scope": {
        "one": "tenant scope",
        "other": "tenant scopes"
      },
      "themes": "learn pathes",
      "translations": "translations",
      "users": "users",
      "vat": "VAT",
      "view_all": "view all",
      "week": "week",
      "withdrawal": "Withdrawal",
      "year": "year"
    },
    "javascript": {
      "format": {
        "both": "DD. MMM YYYY HH:mm",
        "date": "DD. MMM YYYY",
        "time": "HH:mm"
      }
    },
    "labels": {
      "appointments": {
        "deletion": {
          "one_at_least": "an event needs at least one appointment"
        }
      }
    },
    "shared": {
      "choose_category": "choose category...",
      "choose_subcategory": "choose subcategory...",
      "delete_group_definitions_confirm": "You are about to delete the group definition. Are you sure you want to do that?",
      "done": "done",
      "email": "email",
      "week": "Week"
    }
  }
}