import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static targets = ['collapsed']

  toggle(event) {
    const domTarget = $(event.currentTarget)
    const collapseTarget = $(`#${domTarget.data('bs-target')}`)
    if(this.isOpen(collapseTarget)) {
      domTarget.find('.material-icons').html('expand_more')
      this.closeGroup(collapseTarget)
    } else {
      domTarget.find('.material-icons').html('expand_less')
      const id = domTarget.data('id')
      this.openGroup(collapseTarget, id)
    }
  }

  isOpen(target) {
    return target.hasClass('show')
  }

  openGroup(target, id) {
    this.stimulate('JourneyVersions#versions', id).then(() => target.collapse('show'))
  }

  closeGroup(target) {
    target.collapse('hide')
  }

  deleteActiveJourney(event) {
    event.preventDefault()
    const target = $(event.currentTarget)
    const versionId = target.data('id')

    this.stimulate('JourneyVersions#delete_active_journey', versionId)
  }
  
  /**
   * Toggles the journey type based on the clicked element's data attribute and updates the window location.
   *
   * @param {Event} event - The event triggered by clicking the element.
   */
  toggleJourneyType(event) {
    const target = $(event.currentTarget)
    var journey_type = target.data('journey-type')
    const url = new URL(window.location);
    url.searchParams.set('journey_type', journey_type);
    if (journey_type == '2' || journey_type == '3') {
      url.searchParams.delete('page');
    }
    url.searchParams.set('journey_type', journey_type);
    // window.history.pushState({}, '', url);
    window.location = url;
  }
}